import { Button } from '@capturi/ui-components'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Trans } from '@lingui/macro'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { ValueRange as ValueRangeType } from '../../../types'
import { FormField } from './types'

export type NumberInputComponentProps = {
  unit?: string
  numberInput?: React.ReactElement<{
    value: string | number | undefined
    onChange: (value?: number) => void
    min?: number
    max?: number
    id?: string
  }>
}

type PercentageInputProps = {
  onChange?: (value: number | undefined) => void
} & Omit<NumberInputProps, 'onChange'>

const DefaultNumberInput = React.forwardRef<
  HTMLDivElement,
  PercentageInputProps
>(function DefaultNumberInput(
  { value, onChange, min = 0, max, ...restProps },
  ref,
) {
  const format = (val: number | string | undefined): string => {
    if (!Number.isInteger(val)) {
      return ''
    }
    return `${val}`
  }
  return (
    <NumberInput
      ref={ref}
      step={1}
      min={min}
      max={max}
      inputMode="numeric"
      onChange={(_valueString, valueAsNumber) => {
        onChange?.(Number.isInteger(valueAsNumber) ? valueAsNumber : undefined)
      }}
      value={format(value)}
      allowMouseWheel
      w={28}
      size="sm"
      {...restProps}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
})

type ValueRangeProps = {
  value?: ValueRangeType
  onChange?: (value: ValueRangeType) => void
} & Omit<NumberInputProps, 'onChange' | 'value'> &
  NumberInputComponentProps

export const ValueRange = React.forwardRef<HTMLDivElement, ValueRangeProps>(
  function ValueRange(
    {
      id,
      value,
      onChange,
      numberInput: NumberInputComponent = <DefaultNumberInput />,
      unit,
    },
    ref,
  ) {
    const onMinChange = (minValue?: number | null): void => {
      onChange?.({
        min: minValue,
        max: value?.max,
      })
    }

    const onMaxChange = (maxValue?: number | null): void => {
      onChange?.({
        min: value?.min,
        max: maxValue,
      })
    }

    const minInputMaxValue = value?.max != null ? value.max - 1 : undefined
    const maxInputMinValue = value?.min != null ? value.min + 1 : undefined

    return (
      <>
        <Stack ref={ref} direction="row" spacing={8} id={id}>
          <Box>
            <Text fontSize="sm">
              <Trans>Min. value</Trans> {unit && `(${unit})`}
            </Text>
            <Box maxW="12em">
              {React.cloneElement(NumberInputComponent, {
                value: value?.min ?? undefined,
                onChange: onMinChange,
                max: minInputMaxValue,
                id: id,
              })}
            </Box>
            <Button
              variant="link"
              size="sm"
              onClick={() => onMinChange(null)}
              tabIndex={-1}
            >
              <Trans>Reset</Trans>
            </Button>
          </Box>
          <Box>
            <Text fontSize="sm">
              <Trans>Max. value</Trans> {unit && `(${unit})`}
            </Text>
            <Box maxW="12em">
              {React.cloneElement(NumberInputComponent, {
                value: value?.max ?? undefined,
                onChange: onMaxChange,
                min: maxInputMinValue,
              })}
            </Box>
            <Button
              variant="link"
              size="sm"
              onClick={() => onMaxChange(null)}
              tabIndex={-1}
            >
              <Trans>Reset</Trans>
            </Button>
          </Box>
        </Stack>
      </>
    )
  },
)

export const ValueRangeField: FormField<
  null,
  { label: string; description?: React.ReactNode } & NumberInputComponentProps
> = ({ name = 'range', numberInput, isRequired, unit, label, description }) => {
  const id = `widget-${name}`
  const { formState } = useFormContext()
  const isInvalid = formState.errors[name] != null
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor={id}>
        <Text>{label}</Text>
        {description && (
          <Text color="textMuted" fontSize="sm" fontWeight="normal">
            {description}
          </Text>
        )}
      </FormLabel>
      <Controller
        name={name}
        render={({ field }) => (
          <ValueRange
            {...field}
            id={id}
            numberInput={numberInput}
            unit={unit}
          />
        )}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}
